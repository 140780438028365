import React from "react"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"
import JobForm from "../components/job-application-form1"
import "../styles/engineering-challenge.scss"

//Image
import MapImg from "../images/map.png"

const JobVacancy = () => {
  return (
    <Layout>
      <SEO
        title="Job Vacancies"
        description="We are hiring! Metsi is looking for skilled and passionate IT professionals to join our team. Apply for available job vacancies here."
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div className="job-bkg">
        <div className="eng-chal-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Job Vacancies</h1>
                  <h2
                    style={{
                      width: "100%",
                      textTransform: "none",
                      fontWeight: "500",
                      paddingTop: "0px",
                    }}
                  >
                    Do you have what it takes to join our highly skilled team?
                  </h2>
                  <h3 style={{ textTransform: "none" }}>
                    We are hiring! View our vacancies below.
                    {/* No vacancies at the moment. */}
                  </h3>
                  <div
                    id="hiring"
                    style={{ paddingTop: "40px", paddingBottom: "0px" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>1. Data Scientist Apprentice:</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >

                      <p>
                        <strong>Role Summary: </strong> <br />
                        We are looking for a Data Scientist Apprentice to join our world-class team.<br />
                        This role is designed to help you build the fundamental skills needed to succeed in automation, security, and infrastructure,
                        setting you up for a long-term career in technology.
                        You will be hands-on with data, learning from experienced mentors, and working on real-world projects that have a tangible impact.
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/data-scientist-apprentice"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div>
                    {/* <div
                    id="hiring"
                    style={{ paddingTop: "40px", paddingBottom: "0px" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>1. Automation Engineer:</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >
                    
                      <p>
                        <strong>Role Summary: </strong> <br />
                        This is an amazing opportunity for a passionate cloud,
                        virtualisation or infrastructure systems engineer. You
                        should have a wide-ranging knowledge of both solution
                        development and operations, including automation
                        workflow development, infrastructure management, system
                        administration and automation toolchains. You don't need
                        to know it all, we can teach you, but you need to be
                        self-motivated and comfortable in a start-up culture!
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/automation-engineer"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div> */}
                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>1. Automation Engineer:</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >
                    
                      <p>
                        <strong>Role Summary: </strong> <br />
                        This is an amazing opportunity for a passionate cloud,
                        virtualisation or infrastructure systems engineer. You
                        should have a wide-ranging knowledge of both solution
                        development and operations, including automation
                        workflow development, infrastructure management, system
                        administration and automation toolchains. You don't need
                        to know it all, we can teach you, but you need to be
                        self-motivated and comfortable in a start-up culture!
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/automation-engineer"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div>

                    <br />
                    <br />
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>2. Automation DevOps Engineer:</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >
                   
                      <p>
                        <strong>Role Summary: </strong> <br />
                        This is an amazing opportunity for a passionate IT
                        generalist who should have a wide-ranging knowledge of
                        both development and operations, including scripting and
                        coding, infrastructure management, system administration
                        and automation toolchains. You don't need to know it
                        all, we can teach you, but you need to be self-motivated
                        and comfortable in a start-up culture!
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/automation-devops-engineer"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div>

                    <br />
                    <br />

                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>3. Cloud Engineer:</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >
                    
                      <p>
                        <strong>Job Description: </strong> <br />
                        Install and configure Morpheus Data appliance on cloud
                        host(s). Work with Morpheus Data tool to configure apps
                        and provision instances typically in a cloud
                        environment. Customize Morpheus for customer needs.
                        Build tasks, scripts, workflows, jobs, etc to automate
                        processes. Manage users and groups through tenancy and
                        RBAC. Remote work. Must be a US citizen.
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/cloud-engineer"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div>

                    <br />
                    <br />

                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>4. Application Performance Engineer:</h3>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >
                     
                      <p>
                        <strong>Job Description: </strong> <br />
                        Install and configure AppDynamics. Work with AppDynamics
                        tool to instrument applications to be monitored
                        typically in a cloud environment. Build custom
                        dashboards to help highlight key metrics within the
                        customer's application(s). Usually remote work, possibly
                        some travel. Must be a US citizen.
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/application-performance-engineer"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div>
                    <br />
                    <br /> */}

                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <h3>1. Identity Security Consultant:</h3>
                    </div> */}
                    {/* <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "2rem",
                      }}
                    >
                 
                      <p>
                        <strong>Role Summary: </strong> <br />
                        We are looking to expand our Identity Consulting and
                        Professional Services Team with top-level Subject Matter
                        Experts. If you are passionate about helping
                        organisations secure and manage the challenges around
                        Identity and Privileged Access and want to be part of a
                        team that values your genius, drive and can-do attitude,
                        get in touch with us. We are waiting to speak with you.
                      </p>

                      <p>
                        {" "}
                        <a
                          href="/identity-security-consultant"
                          style={{ color: "#005b85", fontWeight: "800" }}
                          target="_blank"
                        >
                          Click to view detailed information and apply
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobVacancy
